/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    font-family: 'Roboto', sans-serif;
  }

h1,h2,h3,h4,h5,h6{
    font-family: 'Roboto', sans-serif;
}
















